export function getProductionConfig() {
  window.conf = {
    domain: "${VUE_APP_SERVER_URL}",
    serverUrl: "https://${VUE_APP_SERVER_URL}",
    controllServerUrl: "https://${VUE_APP_CONTROLL_SERVER_URL}",
    WEB_SOCKET_SERVER_URL: "wss://${VUE_APP_CONTROLL_SERVER_URL}",
    pushServerUrl: "https://${VUE_APP_PUSH_SERVER_URL}",
    pushServerAppId: "${VUE_APP_PUSH_SERVER_APP_ID}",
    pushServerRestId: "${VUE_APP_PUSH_SERVER_REST_ID}",
    chatServerUrl: "https://${VUE_APP_CHAT_SERVER_URL}",
    chatSocketUrl: "wss://${VUE_APP_CHAT_SERVER_URL}/graphql",
    storageUploadServerUrl: "https://${VUE_APP_STORAGE_SERVER_URL}/upload",
    storageServerUrl: "https://${VUE_APP_STORAGE_SERVER_URL}",
    msgServerUrl: "https://${VUE_APP_CHAT_SERVER_URL}",
    audioServerUrl: "https://${VUE_APP_AUDIO_SERVER_URL}",
    omnideskUrl: "https://${VUE_APP_OMNIDESK_URL}",
    sentryDsn: "${VUE_APP_SENTRY_DSN}",
    flashphonerHlsUrl: "${VUE_APP_FLASHPHONER_HLS_URL}",
    wcsLoadBalancerUrl: "${VUE_APP_WCS_LOAD_BALANCER_URL}",
  };
}
